import minify from "@sid/core/util/minify";
import { Fragment, useEffect, useState } from "react";
const NewLandingLayout = ({ children, bgColor }) => {
  const [isTransition, setIsTransition] = useState(false);

  /**
   * Little hack supaya mata mu tidak epilepsi
   */
  useEffect(() => {
    setTimeout(() => {
      setIsTransition(true);
    }, 500);
  }, []);

  return (
    <Fragment>
      <style>
        {minify(`
      body, html {
        overflow-x: hidden;
      }
      body {
        background: ${bgColor};
        ${isTransition ? `transition: all 0.5s;` : ""}
      }
    `)}
      </style>
      {children}
    </Fragment>
  );
};

export default NewLandingLayout;
