import {
  ArrowRightIcon,
  ArrowRightOnRectangleIcon,
  Bars3Icon,
  XMarkIcon,
} from "@heroicons/react/24/solid";
import Button from "@sid/core/components/common/Button";
import { useAppContext } from "@sid/core/context/AppContext";
import { useUserContext } from "@sid/core/context/UserContext";
import { assetPrefix } from "@sid/core/vars/assetUrl";
import homeDomain from "@sid/core/vars/homeDomain";
import clsx from "clsx";
import { Trans, useTranslation } from "next-i18next";
import Link from "next/link";
import { Fragment, useMemo, useState } from "react";
import { useWindowScroll } from "react-use";

type Props = {
  children: React.ReactNode;
  activeSection?: number;
  inverse?: boolean;
  activePage?: string;
  menuContainer?: string;
  style?: any;
  useLink?: boolean;
};

const sidLogo = "sid-neu-logo.svg";
const sidLogoDark = "sid-neu-logo-dark.svg";

const useHeaderMenu = () => {
  const { t } = useTranslation();
  const headerMenu = [
    {
      label: t("header_menu.home"),
      slug: "home",
      url: "/",
    },
    {
      label: t("header_menu.microsite"),
      slug: "microsite",
      url: "/microsite",
    },
    {
      label: t("header_menu.shortener"),
      slug: "shortener",
      url: "/shortener",
    },
    // {
    //   label: t("header_menu.templates"),
    //   slug: "templates",
    //   url: "/templates",
    // },
    {
      label: t("header_menu.subscription"),
      slug: "pricing",
      url: "/subscription",
    },
    {
      label: t("header_menu.about"),
      slug: "about",
      url: "/about",
    },
  ];
  return headerMenu;
};

const MainHeading = ({
  children,
  activeSection = 0,
  activePage,
  menuContainer,
  inverse,
  style,
}: Props) => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const { y: yScroll } = useWindowScroll();

  const { alternate } = useAppContext();

  // redirect to home.s.id if it's alternate pages
  const useLink = !!alternate;

  const showStickyHeader = useMemo(() => {
    return yScroll > 120;
  }, [yScroll]);

  const onToggle = () => setIsMobileMenuOpen(!isMobileMenuOpen);

  return (
    <section
      id="main"
      className={clsx(
        "transition-colors duration-300",
        "bg-[#1F2140] md:rounded-3xl min-h-[700px] md:mt-6 lg:mt-14 flex flex-col text-white",
        activeSection > 3
          ? "!bg-[#741C2B]"
          : activeSection > 0
          ? "!bg-[#161558]"
          : [inverse && "!text-gray-700"]
      )}
      style={activeSection === 0 ? style : {}}
    >
      <div className="w-full px-4 pt-4 md:px-9 md:pt-9">
        <nav className="flex items-center">
          <Link href="/" className="shrink-0" passHref>
            <h1 id="brand-logo" className="flex items-center">
              <img
                className="h-10"
                src={
                  assetPrefix +
                  (activeSection === 0 && inverse
                    ? "images/" + sidLogoDark
                    : "images/" + sidLogo)
                }
                alt="Brand logo"
              />
            </h1>
          </Link>
          <ul className="ml-4 hidden md:flex flex-1 pr-3">
            <RenderMenu
              activePage={activePage}
              inverse={activeSection === 0 && inverse}
            />
          </ul>
          <div className="flex-1 md:hidden"></div>
          <RenderAuth useLink={useLink} />
          <button className="w-10 p-0.5 ml-3 md:hidden" onClick={onToggle}>
            <Bars3Icon />
          </button>
        </nav>

        <div
          className={clsx(
            "fixed left-0 right-0 z-50 transition-all duration-200 top-0 -translate-y-full",
            showStickyHeader ? "!translate-y-0" : ""
          )}
        >
          <div className={clsx("container-xl mx-auto md:px-6", menuContainer)}>
            <nav className="sticky-header p-4 md:py-6 md:px-6 xl:px-9 bg-white md:rounded-b-3xl flex items-center shadow-xl text-gray-800">
              <Link href="/" passHref>
                <h1 className="shrink-0">
                  <img
                    className="h-10"
                    src={assetPrefix + "images/" + sidLogoDark}
                    alt="Brand logo"
                  />
                </h1>
              </Link>
              <ul className="ml-4 hidden md:flex flex-1">
                <RenderMenu activePage={activePage} inverse />
              </ul>
              <div className="flex-1 md:hidden"></div>
              <RenderAuth />
              <button className="w-10 p-0.5 ml-3 md:hidden" onClick={onToggle}>
                <Bars3Icon />
              </button>
            </nav>
          </div>
        </div>
      </div>
      {children}
      <MobileMenu
        isOpen={isMobileMenuOpen}
        onToggle={onToggle}
        activePage={activePage}
        useLink={useLink}
      />
    </section>
  );
};

const RenderMenu = ({ activePage, inverse = false }) => {
  const menus = useHeaderMenu();

  return (
    <>
      {menus.map((item, index) => (
        <li key={index}>
          <Link
            className={clsx(
              "font-montserrat font-medium bg-opacity-50 px-3 py-2 rounded-full text-sm",
              activePage === item.slug
                ? [
                    inverse
                      ? "bg-red-500 text-white !bg-opacity-100"
                      : "bg-black",
                  ]
                : ""
            )}
            href={item.url}
          >
            {item.label}
          </Link>
        </li>
      ))}
    </>
  );
};

const RenderAuth = ({ isMobile = false, useLink = false }) => {
  const { status, authModal } = useUserContext();

  return (
    <>
      {status === "authenticated" ? (
        <Link
          href={
            useLink
              ? homeDomain + "/dashboard?utm_source=sdotid_app"
              : "/dashboard"
          }
          passHref
          legacyBehavior
        >
          <Button
            as="href"
            color="blue"
            roundFull={!isMobile}
            className={clsx(
              "truncate text-sm",
              isMobile
                ? "w-full !px-4 !py-3 font-semibold text-center mb-2"
                : ""
            )}
          >
            <Trans i18nKey="goto_dashboard" />
            <ArrowRightIcon className="w-5 inline-block -mt-1 ml-2 -mr-2" />
          </Button>
        </Link>
      ) : (
        <Button
          onClick={() => {
            if (useLink) {
              window.open(homeDomain + "/auth/login");
            } else {
              authModal.open();
            }
          }}
          roundFull={!isMobile}
          className={clsx(
            "truncate text-sm",
            isMobile ? "w-full !px-4 !py-3 font-semibold text-center mb-2" : ""
          )}
          color="secondary"
        >
          <ArrowRightOnRectangleIcon className="w-5 inline-block mr-2 -mt-0.5" />
          <Trans i18nKey="login_register" />
        </Button>
      )}
    </>
  );
};

const MobileMenu = ({ isOpen, onToggle, activePage, useLink }) => {
  const menus = useHeaderMenu();
  const { t } = useTranslation();

  const userMenus = [
    {
      label: t("dashboard"),
      slug: "dashboard",
      url: "/dashboard",
    },
    {
      label: t("link"),
      slug: "link",
      url: "/dashboard/links",
    },
    {
      label: t("microsite"),
      slug: "microsite",
      url: "/dashboard/microsite",
    },
    {
      label: t("settings"),
      slug: "settings",
      url: "/dashboard/settings",
    },
  ];

  const { status } = useUserContext();

  return (
    <>
      <div
        className={clsx(
          "md:hidden z-50",
          "bg-black h-screen w-screen fixed transition-colors",
          isOpen ? "bg-opacity-50" : "bg-opacity-0 pointer-events-none"
        )}
        onClick={onToggle}
      />
      <div
        className={clsx(
          "md:hidden z-50 rounded-r-xl rounded-b-xl",
          "bg-white p-4 h-screen w-[calc(100vw-5rem)]",
          "transition-transform overflow-y-auto fixed top-0 left-0",
          isOpen ? "translate-x-0" : "-translate-x-full"
        )}
      >
        <header className="flex items-center border-b pb-3 mb-3">
          <Link href="/" className="shrink-0 flex-1" passHref>
            <h1>
              <img
                className="h-10"
                src={assetPrefix + "images/" + sidLogoDark}
                alt="Brand logo"
              />
            </h1>
          </Link>
          <button className="w-10 h-10 p-1 text-black" onClick={onToggle}>
            <XMarkIcon />
          </button>
        </header>
        <RenderAuth isMobile useLink={useLink} />

        {status === "authenticated" && (
          <Fragment>
            <h2 className="text-gray-500 font-bold mb-3 text-sm">USER MENU</h2>
            <ul className="flex flex-col text-gray-500">
              {userMenus.map((item, index) => (
                <li key={index}>
                  <Link
                    className={clsx(
                      "font-montserrat font-medium bg-opacity-50 px-4 py-3 rounded-xl w-full block",
                      activePage === item.slug
                        ? "bg-red-100 text-red-600 !font-bold !bg-opacity-100"
                        : ""
                    )}
                    href={item.url}
                  >
                    {item.label}
                  </Link>
                </li>
              ))}
            </ul>
            <h2 className="text-gray-500 font-bold mb-3 text-sm mt-3">
              LANDING MENU
            </h2>
          </Fragment>
        )}

        <ul className="flex flex-col text-gray-500">
          {menus.map((item, index) => (
            <li key={index}>
              <Link
                className={clsx(
                  "font-montserrat font-medium bg-opacity-50 px-4 py-3 rounded-xl w-full block",
                  activePage === item.slug
                    ? "bg-red-100 text-red-600 !font-bold !bg-opacity-100"
                    : ""
                )}
                href={item.url}
              >
                {item.label}
              </Link>
            </li>
          ))}
        </ul>
      </div>
    </>
  );
};

export default MainHeading;
