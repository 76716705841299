import assetUrl from "@sid/core/vars/assetUrl";
import Head from "next/head";
import { Fragment, useMemo } from "react";

type Props = {
  title?: string | null;
  description?: string | null;
  defaultThumbnail?: string;
  withoutTemplate?: boolean;
  images?: {
    url: string;
  }[];
};

const SeoComponent = (props: Props) => {
  const defaultThumbnail =
    props.defaultThumbnail || `${assetUrl}web-thumbnail.jpg`;
  const title = props.withoutTemplate
    ? props.title || "Home"
    : `${props.title || "Home"} - s.id`;
  const description =
    props.description ||
    `s.id is a platform for people to show their expertise in creating microsites and shortening the shortest links with the code s.id.`;

  const images = useMemo(() => {
    let images = [{ url: defaultThumbnail }];
    if (props?.images?.length && props?.images?.length > 0) {
      images = props.images;
    }
    return images;
  }, [props.images]);

  return (
    <Head>
      <title>{title}</title>
      <meta name="description" content={description} key="description" />
      <meta property="og:title" content={title} key="og-title" />
      <meta
        property="og:description"
        content={description}
        key="og-description"
      />

      <meta
        name="twitter:card"
        content="summary_large_image"
        key="twitter-card"
      />
      <meta name="twitter:title" content={title} key="twitter-title" />
      <meta
        name="twitter:description"
        content={description}
        key="twitter-description"
      />

      {images.map((item, index) => (
        <Fragment key={index}>
          <meta
            property="og:image"
            content={item.url}
            key={`${index}-og-image`}
          />
          <meta
            name="twitter:image"
            content={item.url}
            key={`${index}-twitter-image`}
          />
        </Fragment>
      ))}

      <meta
        name="keywords"
        content="link free, link bio, biolink, links for instagram bio, cara buat link free, url bio, link in bio, buat link bio, how to amke a link in instagram bio, link di bio, short link, url short, perpendek link, link shortener, shorten website link, custom link gratis, mempersingkat link, penyingkat link, link short"
      />
    </Head>
  );
};

export default SeoComponent;
